<template>
    <seccion-datos :titulo="correodatos" @cambios="guardar()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="nombre_correo" >
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.direccioncorreo') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="direccion_correo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.usuario') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="usuario_correo" >
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.contrasenia') }}</label>
                    <input type="password" class="form-control nombre_completo dato" v-model="clave_correo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.servidorimap') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="servidor_correo" >
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.puerto') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="puerto_correo">
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>SSL</label>
                    <select v-model="ssl_correo" class="form-control select">
                        <option value="1">{{ $t('general.si') }}</option>
                        <option value="0">{{ $t('general.no') }}</option>                                 
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.servidorsmtp') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="servidor_smtp_correo" >
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.puerto') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="puerto_smtp_correo">
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>SSL</label>
                    <select v-model="ssl_smtp_correo" class="form-control select">
                        <option value="1">{{ $t('general.si') }}</option>
                        <option value="0">{{ $t('general.no') }}</option>                                 
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input v-model="publico" class="custom-control-input custom-control-input-danger" type="checkbox" id="publico" true-value="1" false-value="0">
                        <label for="publico" class="custom-control-label">{{ $t('general.publico') }}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input v-model="remitente" class="custom-control-input" type="checkbox" id="remitente" true-value="1" false-value="0">
                        <label for="remitente" class="custom-control-label">{{ $t('general.remitente2') }}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <button type="button" class="btn btn-primary " @click="comprobar()">{{ $t('general.comprobar') }}</button>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            id_correo: '',
            nombre_correo: '',
            direccion_correo: '',
            usuario_correo: '',
            clave_correo: '',
            servidor_correo: '',
            puerto_correo: '',
            ssl_correo: 'n',
            servidor_smtp_correo: '',
            puerto_smtp_correo: '',
            ssl_smtp_correo: 'n',
            publico: '',
            remitente: '',
            Seleccionado: '',
            correodatos: "Datos",
        }
    },
    methods: {
        async cargardatos() {
            const api = new PwgsApi;
            const datos = await api.get('correos/' + this.id);
            this.correo = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.nombre_correo = this.correo.nombre_correo;
            this.direccion_correo = this.correo.direccion_correo;
            this.id_correo = this.correo.id_correo;
            this.usuario_correo = this.correo.usuario_correo;
            this.clave_correo = this.correo.clave_correo;
            this.servidor_correo = this.correo.servidor_correo;
            this.puerto_correo = this.correo.puerto_correo;
            this.ssl_correo = this.correo.ssl_correo;
            this.servidor_smtp_correo = this.correo.servidor_smtp_correo;
            this.puerto_smtp_correo = this.correo.puerto_smtp_correo;
            this.ssl_smtp_correo = this.correo.ssl_smtp_correo;
            this.publico = this.correo.publico;
            this.remitente = this.correo.remitente;
            
            this.$store.dispatch('cambiarCargando', false);
            this.bancodatos = this.$t("general.datos");
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = { nombre_correo : this.nombre_correo,
                direccion_correo : this.direccion_correo,
                id_correo : this.id_correo,
                usuario_correo : this.usuario_correo,
                clave_correo : this.clave_correo,
                servidor_correo : this.servidor_correo,
                puerto_correo : this.puerto_correo,
                ssl_correo : this.ssl_correo,
                servidor_smtp_correo : this.servidor_smtp_correo,
                puerto_smtp_correo : this.puerto_smtp_correo,
                ssl_smtp_correo : this.ssl_smtp_correo,
                publico : this.publico,
                remitente : this.remitente, };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('correos/' + this.correo.id_correo, datos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$parent.obtenerCorreo(this.id_correo);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                 catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/bancos */
                try {
                    let res;
                    res = await api.post('correos/', datos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/correos/' + res.id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
        async comprobar() {
            //GET  modulos/pwgsapi/index.php/correos/:id/conexion
            const api = new PwgsApi();
            try{
                await api.get('correos/' + this.$props.id + '/conexion');
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });

            }
         }
    },
    mounted() {
        this.cargardatos();
    }
}
</script>