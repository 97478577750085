<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.correo') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.nuevocorreo') }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos correos -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ correo.nombre_correo }}</h3>
                            <p class="text-muted text-center text-white">{{ correo.direccion_correo }}</p>
                        </div>
                    </div>
                    <!--<div class="callout callout-info">
                  <h5>Última modificación</h5>
                    <p>15/12/2021 15:35h por soporte2</p>
                    </div>-->
                </div>
                <div class="col-md-6">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_correo" data-toggle="tab">{{
                                    $t('general.datos') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_correo">
                            <correos-datos :correos="correos" :id="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import CorreosDatos from './datos/CorreosDatos.vue'
export default {
    components: {
        'correos-datos': CorreosDatos,
    },
    props: ['id'],
    data() {
        return {
            correo: [],
        }
    },
    methods: {
        async obtenerCorreo(id) {
            /* get  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = await api.get('correos/' + id);
            this.correo = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/correos/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.correo.nombre_correo;
                    this.$store.getters.ventanas[i].alt = this.correo.nombre_correo + " || " + this.correo.direccion_correo;
                }
            }
            /**/
          
        },

        cambios() {
            this.obtenerCorreo(this.id);
        },
        recargar() {
            this.obtenerCorreo(this.id);
        },
    },
    mounted() {
        this.obtenerCorreo(this.id);
    },
 
    watch: {
        id() {
            this.obtenerCorreo(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/correos/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.correo.nombre_correo;
                    this.$store.getters.ventanas[i].alt = this.correo.nombre_correo + " || " + this.correo.direccion_correo;
                }
            }
            /**/
        }
       
    }
}
</script>
